/* eslint-disable import/no-extraneous-dependencies */

import Swiper from 'swiper';
import { Navigation, Pagination, A11y } from 'swiper/modules/index.mjs';
import { getScreenSize } from './util';

const MOBILE_MAX_WIDTH = '(max-width: 900px)';
const TABLET_MIN_WIDTH = '(min-width: 901px)';
const LAPTOP_MIN_WIDTH = '(min-width: 1025px)';
const DESKTOP_MIN_WIDTH = '(min-width: 1367px)';

/**
 * Handles the success scenario of the geolocation request
 * @param {Object} position - The position object returned by the browser
 * @param {HTMLElement} $wrapper - The wrapper element of the location button
 */
const locationCallback = (position, $wrapper) => {
    const $error = $wrapper.find('.js-location-error');
    $error.addClass('display-none');
    $error.attr('aria-hidden', 'true');

    let $locationUrl = $wrapper.find('.js-location-address-url');
    if ($locationUrl.length < 1) {
        $locationUrl = $('.js-location-address-url');
    }

    let $searchInput = $wrapper.find('.js-search-input');
    if ($searchInput.length < 1) {
        $searchInput = $('.js-search-input');
    }

    let $searchForm = $wrapper.find('.js-search-bar-form');
    if ($searchForm.length < 1) {
        $searchForm = $('.js-search-bar-form');
    }

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const url = `${$locationUrl.val()}?latitude=${latitude}&longitude=${longitude}`;

    $.ajax({
        type: 'GET',
        dataType: 'json',
        url,
        success: response => {
            if (response && response.address) {
                $searchInput.val(response.address);
                $searchInput.data('latitude', latitude);
                $searchInput.data('longitude', longitude);

                const $submitButton = $searchForm.find('.club-finder__search-button');
                const $clubFinderSubmitButton = $searchForm.find('.search-button');

                if ($submitButton.length > 0) {
                    $submitButton.addClass('club-finder__search-button--active');
                }

                if ($clubFinderSubmitButton.length > 0) {
                    $clubFinderSubmitButton.addClass('search-button--active');
                    $('.js-suggestions').empty();
                }
            }
        },
    });
};

/**
 * Gets the current country code from the browser URL
 * @returns {string} - Country code
 */
const getCountryCode = () => {
    const url = window.location.pathname;
    const segment = url.match(/\/([a-z]{2}-[a-z]{2})\//i);
    const locale = segment && segment[1];
    const localeSegment = locale && locale.split('-');
    const countryCode = localeSegment && localeSegment[1] ? localeSegment[1] : '';

    return countryCode.toUpperCase();
};

/**
 * Gets the current screen resolution
 * @returns {string} - Screen resolution
 */
const getScreenResolution = () => {
    if (getScreenSize(MOBILE_MAX_WIDTH)) {
        return 'mobile';
    } else if (getScreenSize(TABLET_MIN_WIDTH) && !getScreenSize(LAPTOP_MIN_WIDTH)) {
        return 'tablet';
    } else if (getScreenSize(LAPTOP_MIN_WIDTH) && !getScreenSize(DESKTOP_MIN_WIDTH)) {
        return 'laptop';
    }

    return 'desktop';
};

/**
 * Adds parameters to the current browser URL
 * @param {Object} parameters - Search parameters to be added to the current browser URL
 */
const updateUrl = parameters => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    Object.keys(parameters).forEach(key => {
        const value = parameters[key];

        if (value === null) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
    });

    url.search = searchParams.toString();
    window.history.replaceState(null, null, url.toString());
};

/**
 * Determines if the given tile element is the first store tile in the mobile view
 * @param {HTMLElement} $tile - Tile HTML element
 * @param {string} firstId - Id of the first store tile in the grid
 * @param {boolean} isMobile - True if the current screen resolution is mobile, False otherwise
 * @returns {boolean} - True if the given tile element is the first store tile in the mobile view, False otherwise
 */
const isFirstMobileTile = ($tile, firstId, isMobile) => {
    const id = $tile.attr('data-pid');

    return isMobile && id === firstId;
};

/**
 * Initializes swiper
 * @param {HTMLElement} $tile - Store tile JQuery element
 * @param {boolean} isSelected - True of the swiper should be initialized for the selected club tile, False otherwise
 * @returns {?Swiper} - Initialized swiper or undefined
 */
const initSwiper = ($tile, isSelected) => {
    const $swiper = $tile.find('.js-store-tile-swiper');

    if ($swiper.length < 1) {
        return undefined;
    }

    const swiperId = `#${$swiper.attr('id')}`;
    const storeId = $tile.attr('data-pid');

    const options = {
        // configure Swiper to use modules
        modules: [Navigation, Pagination, A11y],
        spaceBetween: 0,
        slidesPerView: 1,
        breakpointsInverse: true,
        centeredSlides: true,
        watchOverflow: true,
        pagination: {
            el: `.js-store-tile-swiper-pagination-${storeId}${isSelected ? '--selected' : ''}`,
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: `.js-store-tile-swiper-next-${storeId}${isSelected ? '--selected' : ''}`,
            prevEl: `.js-store-tile-swiper-prev-${storeId}${isSelected ? '--selected' : ''}`,
        },
        a11y: {
            prevSlideMessage: `${window.resources['slider.prev.slide.label']}`,
            nextSlideMessage: `${window.resources['slider.next.slide.label']}`,
            firstSlideMessage: `${window.resources['slider.first.slide.label']}`,
            lastSlideMessage: `${window.resources['slider.last.slide.label']}`,
        },
    };

    return new Swiper(swiperId, options);
};

/**
* Changes default map view
* @param {Mapbox} map - Mapbox map instance
*/
const adjustMapView = map => {
    if (!map) {
        return;
    }

    map.setFilter('admin-0-boundary-disputed', [
        'all',
        ['==', ['get', 'disputed'], 'true'],
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'MA'], true, false],
    ]);

    map.setFilter('admin-0-boundary-bg', [
        'all',
        ['==', ['get', 'admin_level'], 0],
        ['==', ['get', 'maritime'], 'false'],
        ['match', ['get', 'worldview'], ['all', 'MA'], true, false],
    ]);

    map.setLayoutProperty('country-label', 'text-field', [
        'case',
        ['==', ['get', 'name'], 'Western Sahara'], '',
        ['==', ['get', 'name_en'], 'Western Sahara'], '',
        ['coalesce',
            ['get', 'name_en'],
            ['get', 'name'],
        ],
    ]);
};

export {
    locationCallback,
    getCountryCode,
    getScreenResolution,
    updateUrl,
    isFirstMobileTile,
    initSwiper,
    adjustMapView,
};
