import { debounce } from 'lodash'; // eslint-disable-line import/no-extraneous-dependencies
import location from './components/location';
import { locationCallback } from './searchHelpers';

const SearchBarComponent = (() => {
    function highlightSearchInput() {
        const $searchForm = $('.js-search-bar-form');

        $searchForm.each((index, form) => {
            const $searchInput = $(form).find('.js-search-input').val().toLowerCase();

            $(this).siblings('.js-suggestions').find('.js-result-item').each(() => {
                let $replacedVal;
                const $resultVal = $(this).html().trim().toLowerCase();

                if ($resultVal.indexOf($searchInput) >= 0) {
                    const $reg = new RegExp(`(${$searchInput})`, 'gi');
                    $replacedVal = $resultVal.replace($reg, '<span>$1</span>');
                    $(this).html($replacedVal);
                }
            });
        });
    }

    /**
     * Performs a get AJAX request to get store search suggestions
     * @param {string} url - URL to send request to
     * @param {string} supportType - Support type
     */
    const sendRequest = (url, supportType) => {
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url,
            success(response) {
                let $results = $('.js-suggestions');
                const $submitButton = $('.js-search-bar-form .club-finder__search-button');
                const $clubFinderSubmitButton = supportType ? $(`.search-button#${supportType}`) : $('.js-search-bar-form .search-button');

                if (supportType) {
                    $results = $(`.js-suggestions#${supportType}`);
                }

                if (response.stores.length < 1) {
                    $submitButton.removeClass('club-finder__search-button--active');
                    $clubFinderSubmitButton.removeClass('search-button--active');
                    $clubFinderSubmitButton.prop('disabled', true);
                } else {
                    $submitButton.addClass('club-finder__search-button--active');
                    $clubFinderSubmitButton.addClass('search-button--active');
                    $clubFinderSubmitButton.prop('disabled', false);
                }

                $results.empty();
                $results.html(`<div class="result-wrapper"> ${response.storesResultsHtml} </div>`);
                highlightSearchInput();
            },
        });
    };

    /**
     * Gets store search suggestions
     * @param {string} searchterm - Search term
     * @param {string} supportType - Support type
     * @param {boolean} skipStoreSearch - True if the search needs to be skipped, False otherwise
     */
    const getSearchSuggestions = (searchterm, supportType, skipStoreSearch) => {
        const isSubscriptionClubPage = Boolean($('.js-club-subscription-search').length);
        supportType = supportType === 'club-finder-search-bar' ? '' : supportType;

        let url = $('.js-suggestion-url').val();

        if (supportType) {
            url = $(`.js-suggestion-url#${supportType}`).val();
        }

        url = url.replace('searchterm=searchterm', '');
        url += `${url.indexOf('?') > -1 ? '&' : '?'}searchterm=${searchterm}&skipstoresearch=${skipStoreSearch}&subscriptionClubPage=${isSubscriptionClubPage}`;

        if (!('permissions' in navigator)) {
            sendRequest(url, supportType);
            return;
        }

        navigator.permissions.query({
            name: 'geolocation',
        }).then(status => {
            if (status.state === 'granted') {
                navigator.geolocation.getCurrentPosition(position => {
                    url += `&lat=${position.coords.latitude}&long=${position.coords.longitude}`;

                    sendRequest(url, supportType);
                });
            } else {
                sendRequest(url, supportType);
            }
        });
    };

    function initializeSearchBar() {
        const debounceFunc = debounce(getSearchSuggestions, 1000);
        const $results = $('.js-suggestions');

        $('.js-search-bar-form input[type="search"]').on('focus', e => {
            const searchterm = $(e.currentTarget).val();
            const supportType = $(e.target).attr('id');

            if (searchterm.length >= 3) {
                debounceFunc(searchterm, supportType);
            } else {
                debounceFunc.cancel();
                $results.empty();
            }

            if ($('.js-club-finder').length) {
                $results.removeClass('d-none');
            }
        });

        if ($('.js-club-finder-main').length > 0 || $('.js-subscription-page').length > 0) {
            // condition for suggestions specific for Subscription page
            $('.js-search-bar-form input[type="search"]').on('keyup', (e) => {
                const searchterm = $(e.currentTarget).val();
                const supportType = $(e.target).attr('id');
                // condition to exclude enter key from getting search suggestions
                if (e.keyCode !== 13) {
                    if (searchterm.length >= 3) {
                        debounceFunc(searchterm, supportType);
                    } else {
                        debounceFunc.cancel();
                        $results.empty();
                    }
                }
            });
        } else {
            $('.js-search-bar-form input[type="search"]').on('keyup', e => {
                const searchterm = $(e.currentTarget).val();
                const supportType = $(e.target).attr('id');
                const $submitButton = $('.js-search-bar-form .club-finder__search-button');
                const $clubFinderSubmitButton = $('.js-search-bar-form .search-button');

                if ($submitButton.length > 0) {
                    $submitButton.addClass('club-finder__search-button--active');
                }

                if ($clubFinderSubmitButton.length > 0) {
                    $clubFinderSubmitButton.addClass('search-button--active');
                }

                if (searchterm.length >= 3) {
                    debounceFunc(searchterm, supportType);
                } else {
                    debounceFunc.cancel();
                    $results.empty();
                }
            });
        }

        function containsNumbers(searchterm) {
            return !isNaN(parseFloat(searchterm)) && isFinite(searchterm);
        }

        function validateSearchTermOnSubmit(e) {
            const searchterm = $('.js-search-bar-form input[type="search"]').val();
            const postalCode = containsNumbers(searchterm);

            if ((postalCode) && !(searchterm.length >= 4)) {
                e.preventDefault();
            } else if (!(postalCode) && !(searchterm.length >= 3)) {
                e.preventDefault();
            }
        }

        if ($('.js-club-finder-main').length > 0 || $('.js-subscription-page').length > 0) {
            // condition for submit search form specific for Subscription page
            $('.js-search-bar-form').on('submit', e => {
                validateSearchTermOnSubmit(e);
                // close search suggestion box on search form submit
                $('.js-suggestions').empty();
            });
        } else {
            $('.js-search-bar-form').on('submit', e => {
                e.preventDefault();
                const $target = $(e.target);
                const $clubResultUrl = $('.js-club-result-url');
                const $noResults = $('.js-suggestions .no-results-container');
                const $trainerTypeInput = $target.find('.js-trainer-type');
                const supportType = $target.data('trainer-type');
                const trainerType = $trainerTypeInput.val();

                if ($target.hasClass('js-search-bar-form') && $clubResultUrl.length > 0 && $noResults.length < 1) {
                    const $searchInput = supportType ? $(`.js-search-bar-form input[type="search"]#${supportType}`) : $('.js-search-bar-form input[type="search"]');
                    const q = $searchInput.val();
                    let url = $clubResultUrl.val();

                    if (q) {
                        url += `?q=${q}`;
                    }

                    if (trainerType) {
                        url += `${url.indexOf('?') > -1 ? '&' : '?'}type=${trainerType}`;
                    }

                    if (!('permissions' in navigator)) {
                        window.location = url;
                        return;
                    }

                    navigator.permissions.query({
                        name: 'geolocation',
                    }).then(status => {
                        if (status.state === 'granted') {
                            navigator.geolocation.getCurrentPosition(position => {
                                const lat = position.coords.latitude;
                                const long = position.coords.longitude;

                                if (lat) {
                                    url += `${url.indexOf('?') > -1 ? '&' : '?'}lat=${lat}`;
                                }

                                if (long) {
                                    url += `${url.indexOf('?') > -1 ? '&' : '?'}long=${long}`;
                                }

                                window.location = url;
                            });
                        } else {
                            window.location = url;
                        }
                    });
                }
            });
        }
    }

    function closeSuggestionOnClickOutside() {
        $('body').on('click', (e) => {
            const supportType = e.target.id;

            if (supportType) {
                const input = e.target.closest(`.js-search-input#${supportType}`);

                if (input) {
                    $(`.js-suggestions:not(#${supportType})`).empty();
                    return;
                }
            }

            if (e.target.closest('.js-suggestions') || e.target.closest('.js-search-bar-form')) return;
            $('.js-suggestions').empty();
        });
    }

    return {
        init() {
            initializeSearchBar();
            location(locationCallback);
            closeSuggestionOnClickOutside();
        },
    };
})();

export default (() => {
    // Dom Ready is Mandatory
    $(document).ready(SearchBarComponent.init);
})();
