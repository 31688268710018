import './searchBar';

const StoreSearch = (() => ({
    init() {
        $('form.input-wrapper').on('submit', e => {
            const $clubResultUrl = $('.js-club-result-url');
            const $noResults = $('.js-suggestions .no-results-container');

            if ($(e.target).hasClass('js-club-finder-form') && $clubResultUrl.length > 0 && $noResults.length < 1) {
                const searchTerm = $('.js-club-finder-form input[type="search"]').val();
                window.location = `${$clubResultUrl.val()}?searchterm=${searchTerm}`;
            }
        });
    },
}))();

export default (() => {
    $(document).ready(StoreSearch.init());
})();
