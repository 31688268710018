const Location = (() => {
    let callback;
    let $wrapper;

    /**
     * Handles the success scenario of the geolocation request
     * @param {Object} position - The position object returned by the browser
     */
    const success = position => {
        const $searchWrapper = $('.club-finder-search-wrapper');

        if (callback) {
            callback(position, $wrapper);

            if ($searchWrapper.length > 0) {
                $searchWrapper.removeClass('search-suggestions-modal');
            }
        }
    };

    /**
     * Handles the error scenario of the geolocation request
     */
    const error = () => {
        let $errorDiv = $wrapper.find('.js-location-error');

        if ($errorDiv.length < 1) {
            $errorDiv = $('.js-location-error');
        }
        // if requested, we can show more specific error messages.
        /* switch (error.code) {
            case error.PERMISSION_DENIED:
                $errorDiv.html("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                $errorDiv.html("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                $errorDiv.html("The request to get user location timed out.");
                break;
            default:
                $errorDiv.html("An unknown error occurred.");
                break;
        } */
        $errorDiv.removeClass('display-none');
        $errorDiv.attr('aria-hidden', 'false');
    };

    /**
     * Initializes event listener for the location button
     */
    const initLocationButton = () => {
        $(document).on('click', '.js-location', e => {
            $wrapper = $(e.currentTarget).parent();
            let $errorDiv = $wrapper.find('.js-location-error');

            if ($errorDiv.length < 1) {
                $errorDiv = $('.js-location-error');
            }

            $errorDiv.addClass('display-none');
            $errorDiv.attr('aria-hidden', 'true');

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            }
        });
    };

    return {
        init(currentCallback) {
            if ($('.js-location').length < 1) {
                return;
            }

            callback = currentCallback;
            initLocationButton();
        },
    };
})();

export default Location.init;
